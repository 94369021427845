import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import { Navigate,useHistory } from 'react-router-dom';
import { NO_PACKAGE_ENTRY } from 'configs/AppConfig';

export const Views = () => {
  const { locale, direction } = useSelector((state) => state.theme);
  const currentAppLocale = resources[locale];
  useBodyClass(`dir-${direction}`);
  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const root = window.document.documentElement;
    root.setAttribute('dir', direction);
  }, [direction]);

  const { user: vendorCategories } = useSelector((state) => state.auth);
  
  
  useEffect(() => {
    console.log('entered to main function')
    if (vendorCategories?.length === 0) {
      <Navigate to={NO_PACKAGE_ENTRY} />
    }
  }, [vendorCategories]); // Add dependencies

  return (
    <ConfigProvider direction={direction} locale={currentAppLocale.antd}>
      <Routes />
    </ConfigProvider>
  );
};

export default Views;
