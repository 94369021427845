import fetch from 'auth/FetchInterceptor';

const UserService = {};

const api = 'user';

UserService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

UserService.getProfile = function () {
  return fetch({
    url: `${api}/details`,
    method: 'GET',
  });
};

UserService.getAllEmployees = function (params) {
  return fetch({
    url: `${api}/get/all/admin`,
    method: 'GET',
    params
  });
};

UserService.get = function (id) {
  return fetch({
    url: `${api}/${id}/admin`,
    method: 'GET'
  });
};

UserService.checkSubscription = function (id) {
  return fetch({
    url: `${api}/checkSubscription`,
    method: 'GET'
  });
};



UserService.post = function (data) {
  return fetch({
    url: `${api}/create/byVendor`,
    method: 'POST',
    data: data
  });
};

UserService.gstAdd = function (data) {
  return fetch({
    url: `${api}/gst/add`,
    method: 'POST',
    data: data
  });
};

UserService.accountAdd = function (data) {
  return fetch({
    url: `${api}/account-details/add`,
    method: 'POST',
    data: data
  });
};

UserService.gstEdit = function (data) {
  return fetch({
    url: `${api}/gst/update`,
    method: 'PUT',
    data: data
  });
};

UserService.accountedit = function (data) {
  return fetch({
    url: `${api}/account-details/update`,
    method: 'PUT',
    data: data
  });
};

export default UserService;
