import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY, NO_PACKAGE_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem('auth_user'))
  console.log('entered to public route',user)

  return token && !window.location.pathname.includes('reports') && user?.length==0 ? (
    <Navigate to={NO_PACKAGE_ENTRY} />            //to go to no package page when no package for vendor
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
